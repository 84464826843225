@import './../../assets/styles/app.scss';

.banner {
    position: relative;
    z-index: 1300;
    margin-top: 90px;

    @media (max-width: '1180px') {
        margin-top: 0;
    }

    @media (max-width: '640px') {
        width: 100vw;
    }

    .swiper-slide {
        display: flex !important;
        justify-content: center !important;
        height: 700px !important;

        @media (max-width: '1180px') {
            width: 100% !important;
            height: 500px !important;
        }

        @media (max-width: '820px') {
            width: 100% !important;
            height: 400px !important;
        }

        @media (max-width: '640px') {
            width: 100% !important;
            height: 230px !important;
        }

        .swiper-image {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}

.swiper-button-prev,
.swiper-button-next {
    display: none !important;
}

.swiper-pagination-bullet {
    &-active {
        background: $mainColor2 !important;
    }
}