@mixin card-shadow {
    box-shadow: 10px 10px 5px rgba(206, 174, 158, 0.2);
}

// Display flex center
@mixin display-flex ($justifyContent: center, $alignItems: center) {
    display: flex;
    justify-content: $justifyContent;
    align-items: $alignItems;
}

// Position absolute center
@mixin absolute-center($top: unset, $bottom: unset) {
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    bottom: $bottom;
    top: $top; //0
    left: 0;
    right: 0;
    text-align: center;
}

// Link
@mixin link-scale {
    text-decoration: none;
    font-weight: bolder;
    scale: 1;
    color: $textColor;
    display: block;

    &:link,
    &:visited {
        text-decoration: none;
    }

    &:hover,
    &:focus {
        scale: 1.05;
        // color: $mainColor1;
        text-decoration: underline $mainColor3;
    }

    &:active {
        text-decoration: none;
    }
}

// Button
@mixin btn-style {
    @include display-flex;
    width: fit-content;
    min-width: 200px;
    height: 30px;
    padding: 10px;
    color: #FFF;
    background-color: $mainColor1;
    border: none;
    font: normal normal 500 1rem Poppins;
    box-shadow: 0px 23.5167px 48.9931px rgba(178, 64, 93, 0.1);
    border-radius: 10px;
    cursor: pointer !important;

    @media (max-width: 640px) {
        height: 10px;
        padding: 15px 20px;
    }
}

// Style Box
@mixin box ($justifyContent: center, $alignItems: center, $flex-direction: row) {
    display: flex;
    justify-content: $justifyContent;
    align-items: $alignItems;
    flex-direction: $flex-direction;
    width: 100%;
    max-width: 1180px;

    @media (max-width: 1180px) {
        margin: 25px;
    }

    @media (max-width: 1180px) {
        margin: 0 50px;
    }

    @media (max-width: 640px) {
        max-width: 300px;
    }
}

@mixin custom-scrollbar ($width: 300px, $height: 50px) {
    width: $width;
    height: $height;
    overflow-y: auto;
    padding-right: 15px;

    /* For Firefox*/
    scrollbar-color: rgb(236, 236, 236) #FFFFFF;
    scrollbar-width: thin;

    /* For Chrome and Safari */
    &::-webkit-scrollbar {
        width: 10px;

        @media (max-width: '640px') {
            display: none;
        }
    }

    &::-webkit-scrollbar-track {
        background: #FFFFFF;
        border-radius: 10px;

        @media (max-width: '640px') {
            display: none;
        }
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-color: rgb(236, 236, 236);

        @media (max-width: '640px') {
            display: none;
        }
    }
}