@import './../../assets/styles/app.scss';



.footer {
    background-color: $mainColor2   ;
    color: $mainColor1;
    width: 100%;
    margin-top: 50px;
    z-index: 1250;
    position: relative;
    padding-bottom: 40px;

    .footer-box {
        @include display-flex;
        min-height: 150px;
        padding: 40px;

        @media (max-width: '1180px') {
            flex-direction: column;
        }

        &__item {
            @include display-flex;
            flex-direction: column;
            color: $textColor;
            row-gap: 10px;
            .logo {
                max-width: 50%;
                width: auto;
            }
            .social-logo-line {
                @include display-flex;
                column-gap: 40px;
                width: 100%;
                margin-top: 10px;
            }
        }
    }
}