@import './../../../assets/styles/app.scss';


.images-section {
    @include display-flex;
    flex-direction: column;
    justify-content: space-evenly;
    width: 37%;

    @media (max-width: "640px") {
        width: 100%;
    }

    .all-product-img {
        @include display-flex;
        overflow: auto;
        width: 100%;
        column-gap: 5px;
        margin-top: 20px;
    }

    .product-img-box {
        @include display-flex;
        max-width: 400px;
        height: 400px;
        overflow: hidden;

        @media (max-width: "820px") {
            height: 300px;
        }

        .product-img {
            max-width: 350px;
            width: auto;
            height: 100%;
            max-height: 400px;

            @media (max-width: "1180px") {
                max-width: 300px;
            }

            @media (max-width: "820px") {
                max-width: 200px;
                max-height: 300px;
                height: auto;
                width: auto;
            }
        }
    }

}