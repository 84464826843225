@import './../../assets/styles/app.scss';

.cards {
    column-gap: calc(10% / 3);
    row-gap: 20px;

    @media (max-width:'820px') {
        column-gap: calc(10% / 2);
    }

    @media (max-width:'640px') {
        column-gap: 5%;
    }
}

