@import './../../../assets/styles/app.scss';

.Box {
    display: grid;
    grid-template-columns: 1fr;
    justify-items: center;
    position: relative;
    width: calc(90% / 4);

    @media (max-width: "820px") {
        width: calc(90% / 3);
    }

    @media (max-width: "640px") {
        width: calc(95% / 2);
    }

    &--hovered-effect {
        &:before {
            position: absolute;
            left: 0;
            bottom: 0;
            content: "";
            display: block;
            width: 100%;
            height: 100%;
            transform-origin: 0 bottom 0;
            transform: scaleY(0);
            transition: .4s ease-out;
            background-color: $mC2Opacity05;
        }

        &:hover {
            z-index: 10;

            &:before {
                transform: scaleY(1);
            }
        }
    }

}

.box-item {
    height: 450px;
    width: 80%;
    max-width: 250px;
    grid-area: 1/1/1/1;

    @media (max-width: "1180px") {
        width: 80%;
    }

    @media (max-width: "640px") {
        height: 300px;
    }

    .box-image {
        @include display-flex;
        height: 250px;

        @media (max-width: "640px") {
            height: 150px;
        }

        img {
            max-width: 200px;
            max-height: 250px;

            @media (max-width: "1180px") {
                max-width: 150px;
                max-height: 175px;
            }

            @media (max-width: "640px") {
                max-width: 100px;
                max-height: 150px;
            }
        }
    }

    .box-name {
        @include display-flex;
        font-weight: 500;
        font-size: 1.2rem;
    }

    .box-price {
        display: grid;
        width: 100%;
        color: $textColor;
        font-size: 1rem;
        font-weight: normal;

        &--old {
            width: 45%;
            @media (max-width: "640px") {
                width: 65%;
            }
            &::before {
                content: "";
                flex: 1 0 10px;
                border-top: 2px solid $colorPromotion;
                grid-column: 1;
                grid-row: 1;
                transform: translateY(0.6rem);
            }
        }

        &--promotion {
            width: 100%;
            color: $colorPromotion;
            font-size: 1.5rem;
            font-weight: bold;
        }
    }

    .box-program {
        width: 100%;
        background-color: $colorPromotion;
        color: $mainColor1;
        font-size: 1rem;
        line-height: 1.5;
        padding: 1%;
        width: 100%;
        border-radius: 10px;
    }
}

.box-item {
    &--hovered {
        @include display-flex($justifyContent: space-around);
        flex-direction: column;
        width: 90%;
        height: 450px;
        max-width: 250px;
        grid-area: 1/1/1/1;
        color: $mainColor1;
        z-index: 10;

        @media (max-width: "1180px") {
            width: 80%;
        }

        @media (max-width: "640px") {
            height: 300px;
        }
    }
}