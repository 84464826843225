@import './../../assets/styles/app.scss';

.box-modal {
    @include display-flex($justifyContent: 'space-between');
    @include custom-scrollbar;
    height: 90%;
    overflow-y: scroll;
    min-height: calc(100vh - 200px);
    // width: calc(100vw - 100px);
    flex-direction: column;
    position: absolute;
    top: 50%,;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
    background-color: $mainColor1;
    padding: 20px;

    @media (max-width: "820px") {
        row-gap: 20px;
    }

    @media (max-width: "640px") {
        flex-wrap: wrap;
        padding: 10px !important;
        flex-direction: row;
        justify-content: center;
    }
}