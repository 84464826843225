@import "utils/variables";

@import "utils/mixin";

@import "utils/typography";

@import "utils/responsive";

body {
    margin: 0;
}