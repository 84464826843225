@import './../../../assets/styles/app.scss';

.principal-infos {
    @include display-flex;
    flex-direction: column;
    width: 60%;
    min-height: 500px;
    text-align: center;
    padding: 20px 10px;

    @media (max-width: "820px") {
        min-height: unset;
        height: auto;
    }

    @media (max-width: "640px") {
        width: 100%;
    }

    .price {
        @include display-flex;
        &__line {
            display: grid;
            color: $textColor;
            font-size: 1rem;
            font-weight: normal;
            margin-right: 15px;

            @media (max-width: "820px") {
                margin-right: 5px;
            }

            &::before {
                content: "";
                flex: 1 0 10px;
                border-top: 2px solid $colorPromotion;
                grid-column: 1;
                grid-row: 1;
                transform: translateY(0.85rem);
            }

            &--promotion {
                color: $colorPromotion;
                font-size: 1.5rem;
                font-weight: bold;
            }
        }

    }
}