// Variable colors

$mainColor1: #FFF;
$mainColor2: #3AC96E; // rgba(18, 181, 96, 0.8);
$mainColor3: rgb(247, 107, 138);
$textColor: rgb(3, 10, 68);
$backgroundColor1:  rgb(255, 251, 243);
$colorPromotion: #ff3534;

// Color variant
$mC2Opacity05: rgba(18, 181, 96, 0.2);
$mC2Opacity1: rgba(18, 181, 96, 1);

// Font Types

$mainFont: 'Trade Gothic', 'Roboto Condensed', sans-serif;
$sectionFont: 'Avant Grade', 'Poppins', sans-serif;
