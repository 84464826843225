/* ########## Font size responsive ########## */

// Medium screen
@media (max-width: 1180px) {
    html {
        font-size: 14px; // 1rem
    }
}

@media (max-width: 960px) {
    html {
        font-size: 12px; // 1rem
    }
}

// Small screen
@media (max-width: 640px) {
    html {
        font-size: 11px; // 1rem
    }
}