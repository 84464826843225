@import "../../assets/styles/app.scss";

.AppBar {
    background-color: transparent;
    color: $textColor;
    box-shadow: none;
    z-index: 1300;
    position: relative;
    width: 100%;
    background-color: $mainColor1;

    @media (max-width: '1180px') {
        margin-top: 50px;
    }
}

.Button {
    color: #000;
    font-weight: 600;
}